import { httpsCallable } from "firebase/functions"
import { getFunctionsInTokyoRegion } from "@/utils/firebase"

export function initialize() {
  window.unityBridge.functions = new FunctionsBridge()
}

class FunctionsBridge {
  async call(methodName: string, params: any, callbackObject: string, callbackMethod: string) {
    const functions = getFunctionsInTokyoRegion()
    const method = httpsCallable(functions, methodName)
    try {
      const result = await method(params)
      window.unityInstance.SendMessage(callbackObject, callbackMethod, JSON.stringify(result.data))
    } catch (error) {
      console.error(error)
    }
  }

  async getVirtualTour(callbackObject: string, callbackMethod: string) {
    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.has("event") ? searchParams.get("event") : null

    let returnData = null
    if (code != null) {
      let functions = getFunctionsInTokyoRegion()
      let method = httpsCallable(functions, "getVirtualTourByCode")
      let result = await method({ code: code })
      returnData = result.data
    } else {
      returnData = { result: "success" }
    }

    // console.log(returnData)
    window.unityInstance.SendMessage(callbackObject, callbackMethod, JSON.stringify(returnData))
  }
}
