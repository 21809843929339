import { verifyLoginCode, isValidLoginCodeSession } from "@/utils/firebase"

export function initialize() {
  window.unityBridge.LoginId = new LoginIdBridge()
}

enum VerifyLoginIdResult {
  Success = 0,
  Error = 1,
  ErrorMaxLogin = 2,
}

class LoginIdBridge {
  async verifyLoginId(loginId: string, callbackObject: string, callbackMethod: string) {
    const result = await verifyLoginCode(loginId)
    if (result.result === "success") {
      window.unityInstance.SendMessage(
        callbackObject,
        callbackMethod,
        JSON.stringify({
          result: VerifyLoginIdResult.Success,
          sessionId: result.sessionId,
        })
      )
    } else {
      let errCode = VerifyLoginIdResult.Error
      if (result.result === "error_max_login") {
        errCode = VerifyLoginIdResult.ErrorMaxLogin
      }
      window.unityInstance.SendMessage(
        callbackObject,
        callbackMethod,
        JSON.stringify({
          result: errCode,
          message: result.message,
        })
      )
    }
  }

  async loginCheck(callbackObject: string, callbackMethod: string) {
    const result = await isValidLoginCodeSession()
    if (result.result === "success") {
      window.unityInstance.SendMessage(
        callbackObject,
        callbackMethod,
        JSON.stringify({
          result: true,
          sessionId: result.sessionId,
        })
      )
    } else {
      window.unityInstance.SendMessage(
        callbackObject,
        callbackMethod,
        JSON.stringify({
          result: false,
          message: result.message,
        })
      )
    }
  }
}
