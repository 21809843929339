<script lang="ts">
  import type { StorageReference } from "firebase/storage"
  import { getAuth } from "firebase/auth"
  import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore"

  import { ContentType, type FileSharingData } from "../types"
  import Button from "../share/button.svelte"
  import BgmView from "./bgm.svelte"
  import ImageView from "./image.svelte"
  import PdfView from "./pdf.svelte"
  import VideoView from "./video.svelte"

  export let target: StorageReference
  export let contentType: ContentType
  export let fileSharingKey: string
  export let fileSharings: FileSharingData[]

  const uid: string = getAuth().currentUser.uid
  let promise: Promise<void>
  let fileSharingData: FileSharingData
  let getFileSharingOptions: () => Promise<any>
  let position: number

  function isFileSame(): boolean {
    return fileSharingData.type == contentType && fileSharingData.owner == uid && fileSharingData.name == target.name
  }

  $: target && onChangeTarget()
  $: isCurrentFileShared = fileSharingData && isFileSame()

  async function init(): Promise<void> {
    if (Array.isArray(fileSharings)) {
      fileSharingData = fileSharings.find((data) => data.key == fileSharingKey) ?? { key: fileSharingKey }
      if (contentType == ContentType.PDF && isFileSame()) {
        position = fileSharingData.page - 1
      } else {
        position = 0
      }
    } else {
      fileSharings = []
      fileSharingData = { key: fileSharingKey }
      position = 0
    }
  }

  function onChangeTarget() {
    promise = init()
  }

  async function onClickFileSharing() {
    const options = (await getFileSharingOptions?.()) ?? {}
    const ref = doc(getFirestore(), "app", "settings")

    fileSharingData = {
      ...options,
      ...{
        key: fileSharingKey,
        type: contentType,
        owner: uid,
        name: target.name,
      },
    }
    const newAllowReadFileSharings = [uid, contentType, target.name].join("/")
    fileSharings = [
      // 既存のキーのデータを削除
      ...fileSharings.filter((data) => data.key != fileSharingKey),
      // 更新データを付与
      fileSharingData,
    ]
    const allowReadFileSharings = makeAllowReadFileSharings(fileSharings, newAllowReadFileSharings)

    await updateDoc(ref, { fileSharings })
    await updateDoc(ref, { allowReadFileSharings })
  }

  function makeAllowReadFileSharings(fileSharings: FileSharingData[], newAllowReadFile: string): string[] {
    let allowList: Array<string> = []
    fileSharings.forEach((file) => {
      allowList.push([file.owner, file.type, file.name].join("/"))
    })
    allowList.push(newAllowReadFile)
    return [...new Set(allowList)]
  }

  function onChangePdfPage(ev: CustomEvent<number>) {
    if (isCurrentFileShared && fileSharingData.page != ev.detail) {
      onClickFileSharing()
    }
  }
</script>

<div class="presenter">
  {#await promise then}
    <Button on:click={onClickFileSharing} buttonColor="#5F6ECB" disabled={isCurrentFileShared}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-display"
        viewBox="0 0 16 16"
      >
        <path
          d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4c0 .667.083 1.167.25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75c.167-.333.25-.833.25-1.5H2s-2 0-2-2V4zm1.398-.855a.758.758 0 0 0-.254.302A1.46 1.46 0 0 0 1 4.01V10c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.758.758 0 0 0 .254-.302 1.464 1.464 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.757.757 0 0 0-.302-.254A1.46 1.46 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145z"
        />
      </svg>
      <span>会場に出力</span>
    </Button>
    <div class="preview">
      {#if contentType == ContentType.PDF}
        <PdfView {target} bind:getFileSharingOptions bind:position on:change={onChangePdfPage} />
      {:else if contentType == ContentType.VIDEO}
        <VideoView {target} />
      {:else if contentType == ContentType.IMAGE}
        <ImageView {target} />
      {:else if contentType == ContentType.BGM}
        <BgmView {target} />
      {/if}
    </div>
  {/await}
</div>

<style>
  @keyframes fadein {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
  .presenter {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 576px;
    height: 432px;
    padding: 0.5em;
    color: var(--presentation-foreground);
    background: var(--presentation-background);
    border-radius: 5px;
  }
  svg.bi-display {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
  }
  .preview {
    width: 100%;
    margin-top: 1em;
    flex: 1 1 auto;
    overflow: hidden;
  }
</style>
