<script lang="ts">
  import { createEventDispatcher } from "svelte"

  const dispatch = createEventDispatcher<{
    click: MouseEvent
  }>()

  function onClickButton(e: MouseEvent) {
    dispatch("click", e)
  }
</script>

<button class="close-button" on:click={onClickButton}>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-x"
    viewBox="0 0 16 16"
  >
    <path
      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
    />
  </svg>
</button>

<style>
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25em;
    color: white;
    border: 0.15em solid var(--presentation-foreground);
    border-radius: 9999px;
    background-color: var(--presentation-background);
    cursor: pointer;
  }
  svg {
    width: 2em;
    height: 2em;
  }
</style>
