export function isIOS(): boolean {
  return /iphone|ipod|ipad/.test(navigator.userAgent.toLowerCase())
}

export function isChromeIOS(): boolean {
  if (!isIOS()) {
    return false
  }

  const ua = navigator.userAgent.toLowerCase()
  if (/safari/.test(ua)) {
    return /crios/.test(ua)
  }

  return /chrome/.test(ua)
}

export function isAndroid() {
  return /android/.test(navigator.userAgent.toLowerCase())
}
