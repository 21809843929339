<script lang="ts">
  import type { ComponentType, ComponentProps } from "svelte"
  import type { User } from "firebase/auth"
  import { browserLocalPersistence, browserSessionPersistence, getAuth, signOut } from "firebase/auth"

  import "./reset.css"
  import { init as initFirebase, getMaintStatus } from "./utils/firebase"
  import { LoginView, onLoggedOut, SignInAnonymously, SignInWithCode, UserAuthContainer } from "./components/auth"
  import { signInClaims, signInOnlyOneSession, signInWithoutAnonymously } from "./components/auth/actions"
  import UnityApplication, { quit as quitUnityApp } from "./components/unity-application.svelte"
  import MultipleLoginNotice from "./components/multiple-login-notice.svelte"

  let userAuthOption: ComponentProps<UserAuthContainer> = {}
  let loginComponent: ComponentType
  let loginComponentParams:
    | ComponentProps<LoginView>
    | ComponentProps<SignInWithCode>
    | ComponentProps<SignInAnonymously> = {}

  let promise = init()
  let maintStatus: string | null = null

  async function init() {
    // Firebase を初期化
    await initFirebase()

    // ログイン管理
    const auth = getAuth()
    auth.languageCode = "ja"
    maintStatus = await getMaintStatus()

    const searchParams = new URLSearchParams(location.search)
    if (searchParams.has("code") && !searchParams.has("state")) {
      // 認証コードでログイン
      if (auth.currentUser) {
        // コード認証では、ログイン済みユーザーとコードが一致していることを保証できないため、
        // サインイン済みのユーザーからはサインアウトして認証コードでのログインを強制する
        await signOut(auth)
      }
      loginComponent = SignInWithCode
      loginComponentParams = { code: searchParams.get("code") }
      userAuthOption = {
        action: signInOnlyOneSession,
        persistence: browserSessionPersistence,
      }
    } else if (searchParams.has("login")) {
      // ログイン認証あり
      loginComponent = LoginView
      loginComponentParams = { method: searchParams.get("login") }
      userAuthOption = {
        action: signInWithoutAnonymously,
        persistence: browserLocalPersistence,
      }
    } else if (!import.meta.env.VITE_LIMIT_ACCESS) {
      // 匿名認証でログイン
      loginComponent = SignInAnonymously
    }

    if (import.meta.env.VITE_LIMIT_ACCESS) {
      // ステージング環境
      if (loginComponent == null) {
        loginComponent = LoginView
        loginComponentParams = { title: "検証環境ログイン" }
        userAuthOption = { ...userAuthOption, persistence: browserLocalPersistence }
      }
      // ステージングのカスタムクレームでアクセス制御
      userAuthOption = { ...userAuthOption, action: signInClaims, param: hasStagingClaims }
    }
  }

  onLoggedOut(async () => {
    // 現状ログアウトされる理由は複数端末でのログインがあった場合のみなので、
    // ログイン画面は表示せずに多重ログインの警告画面を表示する
    loginComponent = MultipleLoginNotice
    loginComponentParams = {}
    // ログアウト時に Unity アプリを終了
    await quitUnityApp()
  })

  async function hasStagingClaims(user: User): Promise<boolean> {
    const idTokenResult = await user.getIdTokenResult()
    if (!!idTokenResult.claims.staging) {
      return true
    }
    return false
  }
</script>

{#await promise then}
  {#if typeof maintStatus === "string"}
    <p>
      {@html maintStatus}
    </p>
  {:else}
    <UserAuthContainer {...userAuthOption}>
      <svelte:component this={loginComponent} slot="login" {...loginComponentParams} />
      <UnityApplication slot="app" />
    </UserAuthContainer>
  {/if}
{:catch error}
  <p style="color: red">{error.message}</p>
{/await}

<style>
  :global(body) {
    transition: background-color 0.5s ease-out;
    -webkit-text-size-adjust: 100%;
  }

  @media (max-width: 767.98px) {
    :global(html) {
      font-size: 12px;
    }
  }

  p {
    padding: 1em;
  }
</style>
