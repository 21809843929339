<script lang="ts">
  import { getAuth, signInAnonymously } from "firebase/auth"

  const promise = signInAnonymously(getAuth())
</script>

{#await promise then}
  <div class="signin" />
{:catch error}
  <p style="color: red">{error.message}</p>
{/await}
