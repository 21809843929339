<script lang="ts">
  import { getAuth } from "firebase/auth"
  import { signInWithCode } from "@/utils/firebase"

  export let code: string

  const promise = init()

  async function init() {
    // 認証コードでログイン
    await signInWithCode(getAuth(), code)
  }
</script>

{#await promise then}
  <div class="signin" />
{:catch error}
  <p style="color: red">{error.message}</p>
{/await}
