import type { Analytics } from "firebase/analytics"
import { getAnalytics, logEvent, setUserId } from "firebase/analytics"
import { getAuth } from "firebase/auth"

export function initialize() {
  window.unityBridge.analytics = new AnalyticsBridge()
}

class AnalyticsBridge {
  private analytics: Analytics

  constructor() {
    this.analytics = getAnalytics()
  }

  logEvent(eventName: string, params: any) {
    logEvent(this.analytics, eventName, params)
  }

  resetAnalyticsUser() {
    let uid = getAuth().currentUser?.uid
    if (uid) {
      console.log(`resetAnalyticsUser : ${uid}`)
      setUserId(getAnalytics(), uid)
    }
  }
}
