import type { Firestore } from "firebase/firestore"
import {
  addDoc,
  collection,
  serverTimestamp,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore"
import { uuid } from "@/utils/uuid"
import { httpsCallable } from "firebase/functions"
import { getFunctionsInTokyoRegion } from "@/utils/firebase"
import { getAuth, browserLocalPersistence, signInWithCustomToken, signInAnonymously } from "firebase/auth"

export function initialize() {
  window.unityBridge.LineClient = LineClient
}

class LineClient {
  private redirectUri: string
  constructor() {
    this.redirectUri = window.location.origin
  }

  dispose() {}

  async requestAuthorizeCode() {
    const searchParams = new URLSearchParams(location.search)
    const event = searchParams.has("event") ? searchParams.get("event") : null
    let functions = getFunctionsInTokyoRegion()
    let method = httpsCallable(functions, "lineRequestAuthorizeCode")
    let res: any = await method({ event: event })
    window.location.href = res.data.url
  }

  async isLogin() {
    let auth = getAuth()
    let result = 0
    if (auth.currentUser) {
      let res = await auth.currentUser.getIdTokenResult()
      if (res.claims.lineAuth) {
        result = 1
      }
    }
    window.unityInstance.SendMessage("LineController", "OnReceiveIsLogin", result)
  }

  async getCustomToken() {
    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.has("code") ? searchParams.get("code") : null
    const event = searchParams.has("event") ? searchParams.get("event") : null
    //console.log("getCustomToken auth code:" + code)
    let functions = getFunctionsInTokyoRegion()
    let method = httpsCallable(functions, "lineGetCustomToken")
    let result = await method({ code: code, event: event })
    //console.log(result)
    window.unityInstance.SendMessage("LineController", "OnReceiveGetCustomToken", JSON.stringify(result.data))
  }

  async getLoginStatus() {
    const searchParams = new URLSearchParams(location.search)
    //console.log("getCustomToken auth code:" + code)
    let functions = getFunctionsInTokyoRegion()
    let method = httpsCallable(functions, "lineGetLoginStatus")
    let result = await method({})
    window.unityInstance.SendMessage("LineController", "OnReceiveGetLoginStatus", JSON.stringify(result.data))
  }

  async loginWithCustomToken(customToken: string) {
    //console.log("loginWithCustomToken customToken:" + customToken)
    let auth = getAuth()
    await auth.setPersistence(browserLocalPersistence)
    let result = 0
    try {
      let userCredential = await signInWithCustomToken(auth, customToken)
      result = 1
    } catch (error) {
      const errorCode = error.code
      const errorMessage = error.message
    }
    window.unityInstance.SendMessage("LineController", "OnReceiveLoginWithCustomToken", result)
  }

  async logout() {
    let auth = getAuth()
    await signInAnonymously(auth)
    window.unityInstance.SendMessage("LineController", "OnReceiveLogout", "")
  }

  async getAllNFT() {
    const searchParams = new URLSearchParams(location.search)
    let functions = getFunctionsInTokyoRegion()
    let method = httpsCallable(functions, "lineGetAllNFT")
    let result = await method()
    console.log(result)
    window.unityInstance.SendMessage("LineController", "OnReceiveGetAllNFT", JSON.stringify(result.data))
  }

  async getVirtualTour() {
    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.has("event") ? searchParams.get("event") : null

    let returnData = null
    if (code != null) {
      let functions = getFunctionsInTokyoRegion()
      let method = httpsCallable(functions, "getVirtualTourByCode")
      let result = await method({ code: code })
      returnData = result.data
    } else {
      returnData = { result: "success" }
    }

    //console.log(result)
    window.unityInstance.SendMessage("LineController", "OnReceiveGetVirtualTour", JSON.stringify(returnData))
  }
}
