<script lang="ts">
  import type { StorageReference } from "firebase/storage"
  import { scale, fly } from "svelte/transition"
  import { quintOut } from "svelte/easing"

  import { ContentType, type FileSharingData } from "./types"
  import Materials from "./materials/index.svelte"
  import Presentater from "./presenter/index.svelte"
  import CloseButton from "./share/close-button.svelte"

  export let fileSharingKey: string
  export let showMaterials = true
  let selectedContetType: ContentType = ContentType.PDF
  let showPresentater = false
  let presentationTarget: StorageReference = null
  let fileSharings: FileSharingData[]

  function onStartPresentation(e: CustomEvent) {
    presentationTarget = e.detail.item
    fileSharings = e.detail.fileSharings
    showMaterials = false
    showPresentater = true
  }

  function onEndPresentation() {
    presentationTarget = null
  }

  function onCloseModal() {
    showMaterials = false
  }

  function onClosePresentater() {
    showPresentater = false
  }
</script>

<div class="modal" style:--presentation-foreground="#ffffff" style:--presentation-background="#2e2e2e">
  {#if showMaterials}
    <div class="cover">
      <div
        class="outer"
        in:scale={{ duration: 250, opacity: 0.5, start: 0.8 }}
        out:scale={{ duration: 250, start: 0.8 }}
      >
        <Materials bind:selectedContetType {fileSharingKey} on:startpresentation={onStartPresentation} />
        <div class="close-btn">
          <CloseButton on:click={onCloseModal} />
        </div>
      </div>
    </div>
  {/if}

  {#if showPresentater}
    <div
      class="hover"
      in:fly={{ delay: 100, duration: 250, x: 600, easing: quintOut }}
      out:fly={{ duration: 150, x: 600, opacity: 0.5, easing: quintOut }}
    >
      <Presentater
        on:close={onEndPresentation}
        target={presentationTarget}
        contentType={selectedContetType}
        {fileSharingKey}
        {fileSharings}
      />
      <div class="close-btn">
        <CloseButton on:click={onClosePresentater} />
      </div>
    </div>
  {/if}
</div>

<style>
  .modal {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
  }
  .cover {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: calc(800px + 20vw);
    padding: 15vh 10vw;
    user-select: none;
    pointer-events: initial;
  }
  .outer {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .hover {
    position: absolute;
    bottom: 10%;
    right: 2.5em;
    user-select: none;
    pointer-events: initial;
  }
  .outer,
  .hover {
    padding: 5px;
    border: 3px solid #cecece;
    border-radius: 3px;
  }
  .outer::before,
  .outer::after,
  .hover::before,
  .hover::after {
    content: "";
    position: absolute;
    background-color: #cecece;
    top: 50%;
    width: 6px;
    height: 30%;
  }
  .outer::before,
  .hover::before {
    left: 0;
    transform: translate(-4.5px, -50%);
  }
  .outer::after,
  .hover::after {
    right: 0;
    transform: translate(4.5px, -50%);
  }
  .close-btn {
    position: absolute;
    font-size: 1rem;
    right: calc(-1.5em);
    top: calc(-1.5em);
  }
</style>
