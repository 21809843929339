import { EventEmitter } from "events"

import { initialize as initializeLine } from "./line"
import { initialize as initializeAnalytics } from "./firebase/analytics"
import { initialize as initializeAuthentication } from "./firebase/auth"
import { initialize as initializeFirestore } from "./firebase/firestore"
import { initialize as initializeFunctions } from "./firebase/functions"
import { initialize as initializeStorage } from "./firebase/storage"
import { initialize as initializeLoginId } from "./verify-login-id"

export async function init() {
  if (window.unityBridge) {
    return
  }

  // Unity との相互作用オブジェクト
  window.unityBridge = {}

  const agora = await import("./agora")
  agora.initialize()

  initializeLine()
  initializeAnalytics()
  initializeAuthentication()
  initializeFirestore()
  initializeFunctions()
  initializeStorage()
  initializeLoginId()

  // WebUI 呼び出し用の EventEmitter
  // イベントの定義は /@types/ui-controller.d.ts で行う
  window.unityBridge.uiController = new EventEmitter()
}
