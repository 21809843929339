<script lang="ts" context="module">
  // Unity の終了処理。
  export async function quit(): Promise<void> {
    if (window.unityInstance) {
      await window.unityInstance.Quit()
      window.unityInstance = null
    }
  }
</script>

<script lang="ts">
  import { onDestroy, onMount } from "svelte"
  import { getAuth, signOut } from "firebase/auth"

  import { init as initUnityBridge } from "@/unity-bridge"
  import { getAngle, notify as orientationNotify } from "@/unity-bridge/orientation"

  import { getLogin, isValidLoginCodeSession } from "@/utils/firebase"
  import { clearCache, getBuildNo } from "@/utils/cache"
  import { isChromeIOS } from "@/utils/user-agent"
  import Modal from "./modal.svelte"
  import PresentationModal from "@/components/presentation/modal.svelte"

  let loading: boolean = true
  let showModal = false
  let showRequirement = false
  let showMaterials = false
  let fileSharingKey: string
  let isFirstLoginCheckResult: number | null = null
  const displayLogoutButton = import.meta.env.VITE_LIMIT_ACCESS

  onMount(async (): Promise<void> => {
    // 推奨環境でない場合はダイアログを表示し何もしない
    if (!fulfillRequirement()) {
      showRequirement = true
      return
    }

    const buildNo = await getBuildNo()
    await clearCache(buildNo)

    // Unity との相互作用を初期化
    await initUnityBridge()

    // Cookie 認証の有効性を取得
    if (await getLogin()) {
      const resLogin = await isValidLoginCodeSession()
      if (resLogin.result === "success") {
        isFirstLoginCheckResult = 1
      } else {
        isFirstLoginCheckResult = 0
      }
    }

    startUnityApplication(
      buildNo,
      (unityInstance: UnityInstance) => {
        window.unityInstance = unityInstance

        // 初期状態を通知
        window.unityInstance.SendMessage("Application", "SetOrientation", getAngle())
        if (isFirstLoginCheckResult != null) {
          window.unityInstance.SendMessage("Application", "SetFirstLoginCheckResult", isFirstLoginCheckResult)
        }

        loading = false
      },
      (message?: any) => {
        if (message) {
          console.error(message)
        }
        showModal = true
      }
    )

    onResize()

    window.addEventListener("resize", onResize)
    window.unityBridge.uiController.on("show-documents", onShowMaterials)
  })

  onDestroy(() => {
    window.removeEventListener("resize", onResize)
    window.unityBridge?.uiController?.off("show-documents", onShowMaterials)
  })

  function onResize() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  function onShowMaterials(param: { key: string }) {
    fileSharingKey = param.key
    showMaterials = true
  }

  function onClickLogout() {
    const auth = getAuth()
    signOut(auth)
  }

  function fulfillRequirement(): boolean {
    if (isChromeIOS()) {
      return false
    }

    return true
  }

  function onClickStartVR(): void {
    window.unityInstance.Module.WebXR.toggleVR()
  }
</script>

<div id="unity-container" use:orientationNotify>
  <div id="unity-canvas-container">
    <canvas id="unity-canvas" style="width: 100%; height: 100%" />
  </div>
  <div>
    <button
      id="xr-start"
      hidden
      on:click={onClickStartVR}
      style="width: 8rem; height:3rem; position: fixed; top: 5%; right: 1rem; background-color: black; border: 0.15rem solid white; color: white; border-radius: 0.5rem; font-size:1.6rem;"
      >XR</button
    >
  </div>
  <div id="unity-loading" class:loading>
    <h2>WEBmetaverse</h2>
    <!-- <div id="unity-loading-logo" /> -->
    <div id="unity-loading-bar">
      <div id="unity-progress-bar-empty">
        <div id="unity-progress-bar-full" />
      </div>
    </div>
    <div class="notice-text">
      <span class="warning-audio">
        本サービスでは一部音声が流れます。周辺の環境に合わせて音量を調整してマナーにご配慮ください。
      </span>
      <span class="warning-text icon">
        iOS15.2以降をご利用の方は、動画視聴にあたり設定が必要となります。歯車アイコンをご確認ください。
      </span>
      <span class="warning-text">
        推奨環境：Android｜OS：Android9以降　iOS｜機種：iPhoneXシリーズ以降 OS：iOS14以降
      </span>
      <span>本サービスは動画再生を含むため、Wi-Fiでのご利用を推奨しております。</span>
      <span>ご利用のネット回線速度によっては、読み込みに数分ほどかかる場合がございます。</span>
    </div>
  </div>

  {#if displayLogoutButton}
    <button class="firebase-logout-button" on:click={onClickLogout}>Logout</button>
  {/if}

  <PresentationModal bind:showMaterials {fileSharingKey} />

  <Modal showModal={showRequirement} showOK={false}>
    <div class="requirements">
      <p>
        ご利用のブラウザは、本サービスの推奨環境ではございません。<br />
        以下をご参照のうえ、推奨環境でアクセスしていただきますようお願い致します。
      </p>
      <ul>
        <li>iOS: Safari</li>
        <li>Android: Google Chrome</li>
        <li>PC: Google Chrome</li>
      </ul>
    </div>
  </Modal>

  <Modal {showModal} on:ok={() => location.reload()}>
    <p>
      アプリケーション読み込み中にエラーが発生しました。<br />
      ページを再読み込みします。
    </p>
  </Modal>

  <style>
    body {
      background-color: #222;
    }
  </style>
</div>

<style>
  #unity-container {
    min-height: 100vh; /* Fallback */
    min-height: calc(var(--vh, 1vh) * 100);
  }
  #unity-canvas-container {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  #unity-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    color: white;
    box-sizing: border-box;
  }
  h2 {
    position: absolute;
    width: 90%;
    top: calc(43% - 4.5em);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  #unity-loading.loading {
    display: flex;
  }
  /*
  #unity-loading-logo {
    position: absolute;
    width: 90%;
    height: 45px;
    top: calc(40% - 75px);
    left: 50%;
    transform: translate(-50%);
    background: url(/logo.png) no-repeat center;
    background-size: contain;
  }
  */
  #unity-loading-bar {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
  span {
    font-family: monospace;
    font-size: 0.95rem;
    line-height: 1.625;
  }
  .notice-text {
    position: absolute;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .notice-text > span:last-child {
    margin-bottom: auto;
  }
  .warning-audio {
    font-size: 1.1rem;
    margin-bottom: auto;
  }
  .warning-text {
    display: flex;
    align-items: center;
    color: #ff5050;
  }
  .warning-text.icon::before {
    content: "";
    display: block;
    width: 2em;
    height: 2em;
    padding-right: 0.5em;
    background: url("/TemplateData/warning-icon.png") no-repeat center;
    background-size: contain;
  }
  .requirements > p {
    margin-bottom: 1em;
  }
  #unity-progress-bar-empty {
    width: 141px;
    height: 18px;
    margin-top: 10px;
    background: url("/TemplateData/progress-bar-empty-dark.png") no-repeat center;
  }
  #unity-progress-bar-full {
    width: 0%;
    height: 18px;
    margin-top: 10px;
    background: url("/TemplateData/progress-bar-full-dark.png") no-repeat center;
  }
  .firebase-logout-button {
    position: absolute;
    display: none;
    bottom: 0;
    right: 0;
    padding: 0.5em 1em;
    color: white;
    background: deepskyblue;
    border: none;
    cursor: pointer;
  }
</style>
