export function getAngle() {
  return (screen.orientation || {}).angle ?? window.orientation
}

export function notify(node: HTMLElement) {
  // event handler
  const onOrientationChange = () => {
    let angle = getAngle()
    if (angle === null) {
      return
    }

    if (angle % 180 === 0) {
      // Portrait
      node.dispatchEvent(new CustomEvent("portrait"))
    } else {
      // Landscape
      node.dispatchEvent(new CustomEvent("landscape"))
    }
    window.unityInstance?.SendMessage("Application", "SetOrientation", angle)
  }

  // Screen Orientation API で画面の向きを検出
  if (screen.orientation) {
    screen.orientation.addEventListener("change", onOrientationChange)
    return {
      destroy() {
        screen.orientation.removeEventListener("change", onOrientationChange)
      },
    }
  }

  // 古いベンダー固有イベントで画面の向きを検出
  window.addEventListener("orientationchange", onOrientationChange)
  return {
    destroy() {
      window.removeEventListener("orientationchange", onOrientationChange)
    },
  }
}
