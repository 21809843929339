import type {
  DocumentData,
  Firestore,
  Query,
  QueryFilterConstraint,
  QueryNonFilterConstraint,
  QuerySnapshot,
  SetOptions,
  WhereFilterOp,
} from "firebase/firestore"
import {
  addDoc,
  and,
  collection,
  collectionGroup,
  CollectionReference,
  doc,
  documentId,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  or,
  orderBy,
  query,
  QueryFieldFilterConstraint,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore"
import { uuid } from "@/utils/uuid"

export function initialize() {
  window.unityBridge.firestore = new FirestoreBridge()
}

type UnSubscribeArray = {
  [listenId: string]: () => void
}

type ColumnType = string | { _type: string }

type Condition = WhereCondition | AndCondition | OrCondition

type WhereCondition = {
  where: [ColumnType, WhereFilterOp, any]
}

type AndCondition = {
  and: Condition[]
}

type OrCondition = {
  or: Condition[]
}

type OrderBy = {
  field: string
  direction?: "asc" | "desc"
}

type QueryParameter = {
  collection?: string[]
  collectionGroup?: string
  condition?: Condition[]
  orderBy?: OrderBy[]
  limit?: number
}

export class FirestoreBridge {
  private db: Firestore
  private unsubs: UnSubscribeArray = {}

  constructor() {
    this.db = getFirestore()
  }

  async getDocument(collectionId: string, documentId: string, ...params: string[]) {
    let docRef = doc(this.db, collectionId, documentId)
    while (params.length >= 4) {
      docRef = doc(docRef, params[0], params[1])
      params = params.slice(2)
    }
    await this._getDocument(docRef, params[0], params[1])
  }

  private async _getDocument(docRef: DocumentReference<DocumentData>, callbackObject: string, callbackMethod: string) {
    try {
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        window.unityInstance.SendMessage(
          callbackObject,
          callbackMethod,
          JSON.stringify(FirestoreBridge.customizeToJSON({ ...docSnap.data(), id: docSnap.id }))
        )
        return
      }
    } catch (error: any) {
      console.warn(error)
    }
    window.unityInstance.SendMessage(callbackObject, callbackMethod, "null")
  }

  async getDocuments(collectionId: string, ...params: string[]) {
    let colRef = collection(this.db, collectionId)
    while (params.length >= 4) {
      colRef = collection(doc(colRef, params[0]), params[1])
      params = params.slice(2)
    }
    await this._getDocuments(colRef, params[0], params[1])
  }

  private async _getDocuments(
    colRef: CollectionReference<DocumentData>,
    callbackObject: string,
    callbackMethod: string
  ) {
    try {
      const snapshot = await getDocs(colRef)
      window.unityInstance.SendMessage(
        callbackObject,
        callbackMethod,
        JSON.stringify(FirestoreBridge.queryToJSON(snapshot))
      )
      return
    } catch (error: any) {
      console.warn(error)
    }
    window.unityInstance.SendMessage(callbackObject, callbackMethod, "null")
  }

  async queryDocuments(queryParameter: QueryParameter, callbackObject: string, callbackMethod: string) {
    try {
      const q = FirestoreBridge.makeQuery(this.db, queryParameter)
      const querySnapshot = await getDocs(q)
      window.unityInstance.SendMessage(
        callbackObject,
        callbackMethod,
        JSON.stringify(FirestoreBridge.queryToJSON(querySnapshot))
      )
      return
    } catch (error: any) {
      console.warn(error)
    }
    window.unityInstance.SendMessage(callbackObject, callbackMethod, "null")
  }

  listenDocument(collectionId: string, documentId: string, ...params: string[]): string {
    let docRef = doc(this.db, collectionId, documentId)
    while (params.length >= 4) {
      docRef = doc(docRef, params[0], params[1])
      params = params.slice(2)
    }
    return this._listenDocument(docRef, params[0], params[1])
  }

  private _listenDocument(
    docRef: DocumentReference<DocumentData>,
    callbackObject: string,
    callbackMethod: string
  ): string {
    const unsub = onSnapshot(docRef, (snapshot) => {
      window.unityInstance.SendMessage(
        callbackObject,
        callbackMethod,
        JSON.stringify(FirestoreBridge.customizeToJSON(snapshot.data())) ?? "null"
      )
    })
    const listenId = uuid()
    this.unsubs[listenId] = unsub
    return listenId
  }

  listenDocuments(queryParameter: QueryParameter, callbackObject: string, callbackMethod: string): string {
    const q = FirestoreBridge.makeQuery(this.db, queryParameter)
    const unsub = onSnapshot(q, (querySnapshot) => {
      const data = {
        metadate: querySnapshot.metadata,
        changes: querySnapshot.docChanges().map((change) => {
          return {
            type: change.type,
            oldIndex: change.oldIndex,
            newIndex: change.newIndex,
            doc: FirestoreBridge.customizeToJSON({ ...change.doc.data(), id: change.doc.id }),
          }
        }),
      }
      window.unityInstance.SendMessage(callbackObject, callbackMethod, JSON.stringify(data))
    })
    const listenId = uuid()
    this.unsubs[listenId] = unsub
    return listenId
  }

  stopListening(listenId: string) {
    if (listenId in this.unsubs) {
      this.unsubs[listenId]()
      delete this.unsubs[listenId]
    }
  }

  addDocument(collectionId: string, ...params: string[]) {
    let colRef = collection(this.db, collectionId)
    while (params.length >= 6) {
      colRef = collection(doc(colRef, params[0]), params[1])
      params = params.slice(2)
    }
    this._addDocument(colRef, FirestoreBridge.customizeFromJSON(params[0]), params[1], params[2], params[3])
  }

  private _addDocument(
    colRef: CollectionReference<DocumentData>,
    value: any,
    callbackObject: string,
    callbackMethod: string,
    fallbackMethod: string
  ) {
    addDoc(colRef, value)
      .then((docRef) => {
        window.unityInstance.SendMessage(callbackObject, callbackMethod, docRef.id)
      })
      .catch((error: any) => {
        if (fallbackMethod) {
          window.unityInstance.SendMessage(
            callbackObject,
            fallbackMethod,
            JSON.stringify(error, Object.getOwnPropertyNames(error))
          )
        }
      })
  }

  setDocument(collectionId: string, documentId: string, ...params: string[]) {
    let docRef = doc(this.db, collectionId, documentId)
    while (params.length >= 7) {
      docRef = doc(docRef, params[0], params[1])
      params = params.slice(2)
    }
    this._setDocument(
      docRef,
      FirestoreBridge.customizeFromJSON(params[0]),
      params[1] as SetOptions,
      params[2],
      params[3],
      params[4]
    )
  }

  private _setDocument(
    docRef: DocumentReference<DocumentData>,
    value: any,
    options: SetOptions,
    callbackObject: string,
    callbackMethod: string,
    fallbackMethod: string
  ) {
    setDoc(docRef, value, options)
      .then(() => {
        window.unityInstance.SendMessage(callbackObject, callbackMethod, docRef.id)
      })
      .catch((error: any) => {
        if (fallbackMethod) {
          window.unityInstance.SendMessage(
            callbackObject,
            fallbackMethod,
            JSON.stringify(error, Object.getOwnPropertyNames(error))
          )
        }
      })
  }

  updateDocument(collectionId: string, documentId: string, ...params: string[]) {
    let docRef = doc(this.db, collectionId, documentId)
    while (params.length >= 6) {
      docRef = doc(docRef, params[0], params[1])
      params = params.slice(2)
    }
    this._updateDocument(docRef, FirestoreBridge.customizeFromJSON(params[0]), params[1], params[2], params[3])
  }

  private _updateDocument(
    docRef: DocumentReference<DocumentData>,
    value: any,
    callbackObject: string,
    callbackMethod: string,
    fallbackMethod: string
  ) {
    updateDoc(docRef, value)
      .then(() => {
        window.unityInstance.SendMessage(callbackObject, callbackMethod, docRef.id)
      })
      .catch((error: any) => {
        if (fallbackMethod) {
          window.unityInstance.SendMessage(
            callbackObject,
            fallbackMethod,
            JSON.stringify(error, Object.getOwnPropertyNames(error))
          )
        }
      })
  }

  /**
   * Reference 型の値をシリアライズする処理を上書きします。
   */
  static customizeToJSON(value: any): any {
    if (Array.isArray(value)) {
      // is Array
      for (let i = 0; i < value.length; i++) {
        value[i] = FirestoreBridge.customizeToJSON(value[i])
      }
    } else if (value !== null && typeof value === "object") {
      if (value instanceof DocumentReference) {
        // is DocumentReference
        ;(<any>value).toJSON = () => {
          return {
            _type: "DocumentReference",
            path: value.path,
          }
        }
      } else if (value instanceof CollectionReference) {
        // is CollectionReference
        ;(<any>value).toJSON = () => {
          return {
            _type: "CollectionReference",
            path: value.path,
          }
        }
      } else {
        // is Map
        Object.keys(value).forEach((k) => {
          value[k] = FirestoreBridge.customizeToJSON(value[k])
        })
      }
    }
    return value
  }

  /**
   * クエリ結果をシリアライズする処理を上書きします。
   *
   * @param docs クエリ結果のスナップショット。
   * @returns
   */
  static queryToJSON(querySnapshot: QuerySnapshot<DocumentData>) {
    return {
      metadata: querySnapshot.metadata,
      docs: querySnapshot.docs.map((doc) => FirestoreBridge.customizeToJSON({ ...doc.data(), id: doc.id })),
    }
  }

  /**
   * Unity から受け取った JSON オブジェクトを Firestore 用にカスタマイズします。
   * @param value
   */
  static customizeFromJSON(value: any): any {
    if (Array.isArray(value)) {
      // is Array
      for (let i = 0; i < value.length; i++) {
        value[i] = FirestoreBridge.customizeFromJSON(value[i])
      }
    } else if (typeof value === "object") {
      if (typeof value._type === "string") {
        if (value._type === "DocumentReference" && typeof value.path == "string") {
          // is DocumentReference
          return doc(getFirestore(), value.path)
        } else if (value._type === "CollectionReference" && typeof value.path == "string") {
          // is CollectionReference
          return collection(getFirestore(), value.path)
        } else if (
          value._type === "Timestamp" &&
          typeof value.seconds === "number" &&
          typeof value.nanoseconds === "number"
        ) {
          // is Timestamp
          if (value.seconds < 0 && value.nanoseconds < 0) {
            // サーバータイムスタンプを使う
            return serverTimestamp()
          } else {
            return new Timestamp(value.seconds, value.nanoseconds)
          }
        } else if (value._type === "DocumentId") {
          // is documentId
          return documentId()
        } else {
          // unkonwn type
          console.warn("unknown type:", value)
        }
      } else {
        // is Map
        Object.keys(value).forEach((k) => {
          value[k] = FirestoreBridge.customizeFromJSON(value[k])
        })
      }
    }
    return value
  }

  /**
   * Unity から受け取った {@link QueryParameter} を解析し、Firestore のクエリを生成します。
   *
   * @param db - {@link Firestore} のインスタンス。
   * @param queryParameter - Unity から渡される {@link QueryParameter} のオブジェクト。
   * @returns 生成された `Query` オブジェクト。
   */
  static makeQuery(db: Firestore, queryParameter: QueryParameter): Query<DocumentData, DocumentData> {
    const colRef =
      queryParameter.collection != null
        ? collection(db, queryParameter.collection[0], ...queryParameter.collection.slice(1))
        : collectionGroup(db, queryParameter.collectionGroup)

    // query 条件を解析する
    const queryConstraint = FirestoreBridge.parseConditions(queryParameter.condition)

    const constraints: QueryNonFilterConstraint[] = []
    if (queryParameter.orderBy != null) {
      queryParameter.orderBy.forEach((o) => constraints.push(orderBy(o.field, o.direction)))
    }
    if (queryParameter.limit != null) {
      constraints.push(limit(queryParameter.limit))
    }

    if (queryConstraint == null) {
      return query(colRef, ...constraints)
    } else if (queryConstraint instanceof QueryFieldFilterConstraint) {
      return query(colRef, queryConstraint, ...constraints)
    } else {
      return query(colRef, queryConstraint, ...constraints)
    }
  }

  /**
   * 渡された {@link Condition} の配列を解析し、Firestore のクエリ条件を生成します。
   *
   * @param conds - {@link Condition} の配列。
   * @returns 生成されたクエリ条件。
   */
  static parseConditions(conds: Condition[]): QueryFilterConstraint | null {
    if (conds == null || conds.length === 0) {
      return null
    } else if (conds.length === 1) {
      return FirestoreBridge.parseCondition(conds[0])
    } else {
      return and(...conds.map((c) => FirestoreBridge.parseCondition(c)))
    }
  }

  /**
   * 渡された {@link Condition} を解析し、Firestore の where, and, or クエリを生成します。
   *
   * @param cond - {@link Condition} オブジェクト。
   * @returns 生成されたクエリ条件。
    @throws 不正な {@link Condition} オブジェクトだった場合、例外を送出します。
   */
  static parseCondition(cond: Condition): QueryFilterConstraint {
    if ("where" in cond) {
      return where(
        FirestoreBridge.customizeFromJSON(cond.where[0]),
        cond.where[1],
        FirestoreBridge.customizeFromJSON(cond.where[2])
      )
    } else if ("and" in cond) {
      return and(...cond.and.map((c) => FirestoreBridge.parseCondition(c)))
    } else if ("or" in cond) {
      return or(...cond.or.map((c) => FirestoreBridge.parseCondition(c)))
    } else {
      throw new Error("unknown condition: " + cond)
    }
  }
}
