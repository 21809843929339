<p>
  ログイン上限数に達したためログアウトしました。<br />
  ブラウザ、またはタブを閉じてから再度ログインしてください。
</p>

<style>
  p {
    color: red;
  }
</style>
