import { getAuth } from "firebase/auth"

export function initialize() {
  window.unityBridge.auth = new AuthenticationBridge()
}

class AuthenticationBridge {
  constructor() {}

  getUserId(): string {
    return getAuth().currentUser.uid
  }

  async getClaims(callbackObject: string, callbackMethod: string) {
    const idTokenResult = await getAuth().currentUser.getIdTokenResult()
    window.unityInstance.SendMessage(callbackObject, callbackMethod, JSON.stringify(idTokenResult.claims))
  }
}
