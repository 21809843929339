<script lang="ts">
  import type { StorageReference } from "firebase/storage"
  import { listAll, getDownloadURL } from "firebase/storage"
  import { createEventDispatcher, onMount } from "svelte"

  export let target: StorageReference
  export let position = 0
  let smallPreviewPosition: number = 0
  let mouseover: boolean = false
  let previewWidth: number = 560
  let smallPreviewWidth: number = 200
  let smallPreviewLeft: number = 0

  const dispatch = createEventDispatcher<{
    change: number
  }>()

  type Item = {
    ref: StorageReference
    getURL: () => Promise<string>
    index: number
  }
  let items: Item[] = []
  $: shows = items.slice(position, position + 3)

  onMount(async () => {
    const result = (await listAll(target)).items
      .filter((x) => x.name.endsWith(".jpg") && !x.name.startsWith("thumbnail."))
      .sort((a, b) => {
        const x = parseInt(a.name.slice(0, a.name.indexOf(".")))
        const y = parseInt(b.name.slice(0, b.name.indexOf(".")))
        if (x < y) return -1
        if (x > y) return 1
        return 0
      })
      .map((x, idx) => {
        return {
          ref: x,
          getURL: getCachedDownloadURL(x),
          index: idx,
        }
      })
    const dummy = { ref: null, getURL: null, index: -1 }
    items = [dummy, ...result, dummy]
  })

  export async function getFileSharingOptions(): Promise<any> {
    const url = new URL(await items[position + 1].getURL())
    url.search = ""
    return {
      url: url.toString(),
      page: position + 1,
    }
  }

  function getCachedDownloadURL(ref: StorageReference): () => Promise<string> {
    let cache = null
    return async (): Promise<string> => {
      if (cache) {
        return cache
      }
      cache = await getDownloadURL(ref)
      return cache
    }
  }

  function onClickItem(ev: MouseEvent) {
    const idx = (<HTMLElement>ev.currentTarget).dataset.idx
    if (idx == "0") {
      position -= 1
    } else if (idx == "2") {
      position += 1
    }
    dispatch("change", position + 1)
  }

  function onClickBar(ev: MouseEvent) {
    const id = (<HTMLElement>ev.currentTarget).dataset.id
    position = Number(id)
    dispatch("change", position + 1)
  }

  function handleMouseOver(ev: MouseEvent) {
    const id = (<HTMLElement>ev.currentTarget).dataset.id
    smallPreviewPosition = Number(id)
    smallPreviewLeft = previewWidth * ((smallPreviewPosition + 0.5) / (items.length - 2)) - smallPreviewWidth / 2
    if (smallPreviewLeft < 0) {
      smallPreviewLeft = 0
    } else if (smallPreviewLeft >= previewWidth - smallPreviewWidth) {
      smallPreviewLeft = previewWidth - smallPreviewWidth
    }
    mouseover = true
  }

  function handleMouseOut(ev: MouseEvent) {
    mouseover = false
  }
</script>

<div class="pdf">
  {#each shows as item, idx (item.index)}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="container"
      on:click={onClickItem}
      data-idx={idx}
      data-clickable={item.index != position && item.index != -1 ? "true" : "false"}
      role="button"
      tabindex="0"
    >
      {#if item.getURL}
        {#await item.getURL() then url}
          <img src={url} alt={item.ref.name} decoding="async" />
        {/await}
      {/if}
    </div>
  {/each}
  <p>{position + 1} / {items.length - 2}</p>
  <div class="toolbar">
    {#each items as item, idx}
      {#if idx > 0 && idx <= items.length - 2}
        {#if position >= idx - 1}
          <!-- svelte-ignore a11y-mouse-events-have-key-events -->
          <button
            class="toolbar-progress active"
            on:click={onClickBar}
            on:mouseover={handleMouseOver}
            on:mouseout={handleMouseOut}
            type="button"
            data-id={idx - 1}
            style={`width: calc(100% * (1 / ${items.length - 2}));`}
          />
        {:else}
          <!-- svelte-ignore a11y-mouse-events-have-key-events -->
          <button
            class="toolbar-progress"
            on:click={onClickBar}
            on:mouseover={handleMouseOver}
            on:mouseout={handleMouseOut}
            type="button"
            data-id={idx - 1}
            style={`width: calc(100% * (1 / ${items.length - 2}));`}
          />
        {/if}
      {/if}
    {/each}
  </div>
  {#if mouseover}
    <div class="small-preview" style={`left: ${smallPreviewLeft}px`}>
      {#if items[smallPreviewPosition + 1].getURL}
        {#await items[smallPreviewPosition + 1].getURL() then url}
          <img class="small-preview-img" src={url} alt={items[smallPreviewPosition + 1].ref.name} decoding="async" />
        {/await}
        <p class="small-preview-page">{smallPreviewPosition + 1} / {items.length - 2}</p>
      {/if}
    </div>
  {/if}
</div>

<style>
  .pdf {
    display: grid;
    width: 100%;
    height: 100%;
    grid-gap: 1em;
    grid-template-areas:
      "main main"
      "prev next"
      "page page"
      "toolbar toolbar";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2.5fr 1fr 1.5em 1.5em;
    position: relative;
  }
  .pdf > div:nth-child(1) {
    grid-area: prev;
  }
  .pdf > div:nth-child(2) {
    grid-area: main;
  }
  .pdf > div:nth-child(3) {
    grid-area: next;
  }
  .pdf > p {
    grid-area: page;
    justify-self: center;
    align-self: center;
  }
  .container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .toolbar {
    grid-area: toolbar;
    width: 100%;
    height: 1em;
    background-color: white;
    display: flex;
  }
  .toolbar-progress {
    border: none;
    height: 100%;
  }
  .toolbar-progress.active {
    background-color: #009287;
  }
  .small-preview {
    position: absolute;
    width: 200px;
    height: 150px;
    background: #b1b3b6;
    bottom: 30px;
  }
  .small-preview-img {
    padding: 10px;
  }
  .small-preview-page {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: bold;
  }
  .container[data-clickable="true"] {
    cursor: pointer;
  }
  .container[data-clickable="false"] {
    pointer-events: none;
  }
  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
</style>
